.root
  position: relative
  max-width: 100%
  .group
    flex-wrap: nowrap
    .append, .value
      background: #fff
    .value
      padding: 18px 25px
      font-size: 16px
      border-radius: 8px
      border: none
      flex-grow: 1
    .append
      border: none
      border-radius: 8px
      padding-right: 25px
    .placeholder
      opacity: 0.6