.users
  .userItem
    position: relative
    width: 100%
    border-bottom: 1px solid #E2E2EA
    padding: 22px 0
    display: flex
    justify-content: space-between
    align-items: center
    .main
      display: flex
      width: 100%
      align-items: center
      .name
        font-weight: 700
        font-size: 16px
        line-height: 120%
        letter-spacing: 0.005em
        color: #11142D
        max-width: 180px
        margin-right: 15px
        width: 335px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
      .email
        color: #B5BCEB
        font-size: 12px
        font-weight: 700
    .actions
      display: flex
      align-items: center
      cursor: pointer
      .edit
        margin: 0 10px
        font-size: 13px
        color: #3F4FB7
        text-decoration: underline

.select
  .MuiList-root
    max-width: 100%
