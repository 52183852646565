.root
  margin-bottom: 15px
  .button
    width: 100%
    background: #FFFFFF
    border: 1px solid #F1F1F6
    border-radius: 8px
    display: flex
    align-items: center
    padding: 0 30px
    height: 118px
    cursor: pointer
    transition: 0.3s
    text-decoration: none
    margin-bottom: 15px
    &:hover
      background: #3F4FB7
      .label
        color: #FFFFFF
      .projects, .blockedHosts, .statistics
        *
          fill: #fff
    .label
      font-weight: bold
      font-size: 24px
      color: #11142D
      margin-left: 22px
      transition: 0.3s
      font-size: 20px
      text-align: left
      flex-grow: 1
      @media screen and (min-width: 768px)
        font-size: 24px
        text-align: center
    .excel // png
      width: 50px
      height: 50px
      background-position: center
      background-size: contain
      background-repeat: no-repeat
      flex-shrink: 0
    .projects, .blockedHosts, .statistics
      width: 50px
      height: 50px
      flex-shrink: 0
      *
        fill: #3F4FB7
    .projects
      transform: scale(2.4)
    .blockedHosts
      transform: scale(1.4)