.root
  & >div
    max-width: 60%
.container
  padding: 40px
  background-color: #E2E2EA
  .title
    font-size: 24px
    margin-bottom: 15px
  .input
    margin-bottom: 15px
  .select
    border: 1px solid #F1F1F6
    font-size: 1rem
    font-weight: 400
    line-height: 1.5
    padding: 18px 25px
    margin-bottom: 15px
  .actions
    display: flex
    justify-content: flex-end
    margin-top: 8px
    .cancel
      margin-right: 15px
      background-color: transparent !important
      border: 1px solid #FB7348 !important
      color: #FB7348 !important
    .cancel, .ok
      padding: 10px 15px
