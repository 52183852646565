.table
    tbody
        tr
            border-top: 1px solid #F7F7F7
.searchInput
    input
        padding: 11px 12px 11px 0 !important
.pagination
    &.MuiTablePagination-spacer
        display: none
.paginationInput
    padding: 5px 0px 3px
    text-align: center
    border-radius: 5px
    border: 1px solid #eee
    &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
    &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

.bodyCell
    p
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        max-width: 500px
