$breakpoint-tablet: 1440px
$breakpoint-mobile: 767px
  
.window
  margin-bottom: 90px

.root
  .button
    width: 100%
    background: #FFFFFF
    border: 1px solid #F1F1F6
    border-radius: 8px
    display: flex
    align-items: center
    padding: 0 30px
    height: 118px
    cursor: pointer
    transition: 0.3s
    text-decoration: none
    &:hover
      background: #3F4FB7
      .label
        color: #FFFFFF
      .projects, .blockedHosts, .statistics
        *
          fill: #fff

    .excel // png
      width: 50px
      height: 50px
      background-position: center
      background-size: contain
      background-repeat: no-repeat
      flex-shrink: 0
    .projects, .blockedHosts, .statistics
      width: 50px
      height: 50px
      flex-shrink: 0
      *
        fill: #3F4FB7
    .projects
      transform: scale(2.4)
    .blockedHosts
      transform: scale(1.4)

.label
  font-weight: bold
  font-size: 24px
  color: #11142D
  transition: 0.3s
  font-size: 20px
  flex-grow: 1
  @media screen and (min-width: 768px)
    font-size: 24px

.input
  margin-top: 16px !important
  margin-bottom: 16px !important
  & > input
    padding: 10px
    border: 1px solid #F1F1F6 !important
    background-color: rgba(255, 255, 255, 0.1) !important
    color: white !important
    border-radius: 8px
    cursor: pointer
    transition: border-color 200ms
    &:hover
      border-color: #3F4FB7
    &::placeholder
      color: white !important
  & > span
    color: white !important

.loaderPage
  position: absolute
  inset: 0
  z-index: 999
  display: flex
  justify-content: center
  align-items: center
  background: rgba(255, 255, 255, 0.5)
  .loaderIcon
    position: fixed
    top: 50%
    width: fit-content

.recentlyActivity
  & > div:nth-child(2)
    padding: 1rem 1rem 0
    & *
      position: relative
      z-index: 10
    & > header
      font-weight: 400
      font-size: 24px
      line-height: 33px

.layout
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-template-rows: 245px 492px
  grid-column-gap: 24px
  grid-template-areas: "welcomeBanner welcomeBanner news" "projects chatbot chatbot" "suppliers archive nothing"
  grid-row-gap: 24px
  @media screen and (max-width: $breakpoint-tablet)
    grid-template-areas: "welcomeBanner news" "projects chatbot" "suppliers archive"
    grid-template-rows: 236px auto auto
    grid-row-gap: 20px
    grid-template-columns: 1fr 1fr
  @media screen and (max-width: $breakpoint-mobile)
    grid-template-areas: "welcomeBanner" "news" "projects" "chatbot" "suppliers" "archive"
    grid-template-rows: inherit
    grid-row-gap: 14px
    grid-template-columns: 1fr
  .welcomeBanner
    grid-area: welcomeBanner
    
  .newsWidget
    grid-area: news
  .projectWidget
    grid-area: projects
  .analyticsWidget
    .scrollable
      ::-webkit-scrollbar
        display: none
      -ms-overflow-style: none
      scrollbar-width: none
    grid-area: analytics
  .chatbotWidget
    grid-area: chatbot
  .suppliersWidget
    grid-area: suppliers
  .archiveWidget
    grid-area: archive

.layoutNotAdmin
  display: grid
  grid-template-areas: 'welcomeBanner welcomeBanner news' "projects chatbot chatbot" "suppliers empty empty"
  grid-template-rows: 245px 492px
  grid-template-columns: 1fr 1fr 1fr
  grid-column-gap: 24px
  grid-row-gap: 24px
  @media screen and (max-width: $breakpoint-tablet)
    grid-template-areas: "welcomeBanner news" "projects chatbot" "suppliers empty"
    grid-template-rows: 236px 364px 365px
    grid-row-gap: 20px
    grid-template-columns: 1fr 1fr
  @media screen and (max-width: $breakpoint-mobile)
    grid-template-areas: "welcomeBanner" "news" "projects" "chatbot" "suppliers"
    grid-template-rows: inherit
    grid-row-gap: 14px
    grid-template-columns: 1fr
  .welcomeBanner
    grid-area: welcomeBanner
    
  .newsWidget
    grid-area: news
  .projectWidget
    grid-area: projects
  .analyticsWidget
    .scrollable
      ::-webkit-scrollbar
        display: none
      -ms-overflow-style: none
      scrollbar-width: none
    grid-area: analytics
  .chatbotWidget
    grid-area: chatbot
  .suppliersWidget
    grid-area: suppliers
  .archiveWidget
    grid-area: archive