.container
  background-color: #3F4FB7
  color: #fff
  text-align: center
  .title
    font-size: 48px
    font-weight: 700
    padding-top: 50px
    margin-bottom: 30px
    @media screen and (min-width: 768px)
      font-size: 62px
  .description
    font-size: 16px
    margin-bottom: 10px
  .window
    background-color: #E2E2EA
    border-radius: 24px
    padding: 30px 20px 20px
    max-width: 1024px
    margin: 0 auto
    position: relative
    bottom: -45px
    color: initial
    @media screen and (min-width: 992px)
      padding: 60px 104px 50px
    &:before
      display: none
      position: absolute
      content: ''
      top: -14px
      right: 74px
      width: 6px
      height: 6px
      border-radius: 50%
      background: #99D3FF
      box-shadow: 22px 0 0 0 #99D3FF, 44px 0 0 0 #99D3FF, 66px 0 0 0 #99D3FF, 88px 0 0 0 #99D3FF, 110px 0 0 0 #99D3FF, 0 22px 0 0 #99D3FF, 22px 22px 0 0 #99D3FF, 44px 22px 0 0 #99D3FF, 66px 22px 0 0 #99D3FF, 88px 22px 0 0 #99D3FF, 110px 22px 0 0 #99D3FF, 0 44px 0 0 #99D3FF, 22px 44px 0 0 #99D3FF, 44px 44px 0 0 #99D3FF, 66px 44px 0 0 #99D3FF, 88px 44px 0 0 #99D3FF, 110px 44px 0 0 #99D3FF, 0 66px 0 0 #99D3FF, 22px 66px 0 0 #99D3FF, 44px 66px 0 0 #99D3FF, 66px 66px 0 0 #99D3FF, 88px 66px 0 0 #99D3FF, 110px 66px 0 0 #99D3FF, 0 88px 0 0 #99D3FF, 22px 88px 0 0 #99D3FF, 44px 88px 0 0 #99D3FF, 66px 88px 0 0 #99D3FF, 88px 88px 0 0 #99D3FF, 110px 88px 0 0 #99D3FF, 0 110px 0 0 #99D3FF, 22px 110px 0 0 #99D3FF, 44px 110px 0 0 #99D3FF, 66px 110px 0 0 #99D3FF, 88px 110px 0 0 #99D3FF, 110px 110px 0 0 #99D3FF
    &:after
      display: none
      position: absolute
      content: ''
      left: -56px
      bottom: 94px
      width: 6px
      height: 6px
      border-radius: 50%
      background: #99D3FF
      box-shadow: 22px 0 0 0 #99D3FF, 44px 0 0 0 #99D3FF, 66px 0 0 0 #99D3FF, 88px 0 0 0 #99D3FF, 110px 0 0 0 #99D3FF, 0 22px 0 0 #99D3FF, 22px 22px 0 0 #99D3FF, 44px 22px 0 0 #99D3FF, 66px 22px 0 0 #99D3FF, 88px 22px 0 0 #99D3FF, 110px 22px 0 0 #99D3FF, 0 44px 0 0 #99D3FF, 22px 44px 0 0 #99D3FF, 44px 44px 0 0 #99D3FF, 66px 44px 0 0 #99D3FF, 88px 44px 0 0 #99D3FF, 110px 44px 0 0 #99D3FF, 0 66px 0 0 #99D3FF, 22px 66px 0 0 #99D3FF, 44px 66px 0 0 #99D3FF, 66px 66px 0 0 #99D3FF, 88px 66px 0 0 #99D3FF, 110px 66px 0 0 #99D3FF, 0 88px 0 0 #99D3FF, 22px 88px 0 0 #99D3FF, 44px 88px 0 0 #99D3FF, 66px 88px 0 0 #99D3FF, 88px 88px 0 0 #99D3FF, 110px 88px 0 0 #99D3FF, 0 110px 0 0 #99D3FF, 22px 110px 0 0 #99D3FF, 44px 110px 0 0 #99D3FF, 66px 110px 0 0 #99D3FF, 88px 110px 0 0 #99D3FF, 110px 110px 0 0 #99D3FF
    @media screen and (min-width: 1200px)
      &:before, &:after
        display: block