.button
  font-size: 14px
  width: fit-content
  background: #FFFFFF
  border-radius: 8px
  display: flex
  align-items: center
  padding: 0.5rem 0.75rem
  cursor: pointer
  transition: 0.3s
  text-decoration: none
  .label
    color: #FFFFFF
    .projects, .blockedHosts, .statistics
      *
        fill: #fff
  .label
    font-weight: bold
    color: #11142D
    margin-left: 4px
    transition: 0.3s
    text-align: left
    flex-grow: 1
    @media screen and (min-width: 768px)
      text-align: center
  .excel // png
    width: 30px
    height: 30px
    background-position: center
    background-size: contain
    background-repeat: no-repeat
    flex-shrink: 0
  .projects, .blockedHosts, .statistics
    width: 30px
    height: 30px
    flex-shrink: 0
    *
      fill: #3F4FB7
  .projects
    transform: scale(2.4)
  .blockedHosts
    transform: scale(1.4)

.input > input
  box-shadow: none !important
  border-radius: 0 !important
  border: 1px solid #F1F1F6 !important
  height: 48px !important
  padding: 0.5rem 0.75rem !important

.icon
  @media (max-width: 425px)
    path
      fill: #134B98 !important
