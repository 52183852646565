.container
  padding: 40px
  background-color: #E2E2EA
  .title
    font-size: 24px
    margin-bottom: 15px
  .input
    margin-bottom: 15px
  .actions
    display: flex
    justify-content: flex-end
    .cancel
      margin-right: 15px
      background-color: transparent !important
      border: 1px solid #FB7348 !important
      color: #FB7348 !important
    .cancel, .ok
      padding: 10px 15px

.form
  display: flex
  flex-wrap: wrap
  padding: 44px
  background: #E2E2EA
  @media screen and (min-width: 768px)
    flex-wrap: nowrap
    .input
      margin-right: 0
      margin-bottom: 15px
      @media screen and (min-width: 768px)
        margin-right: 5px
        margin-bottom: 0
    .regionSelect
      margin-right: 0
      margin-bottom: 15px
      border-radius: 8px
      padding: 18px 25px
      font-size: 1rem
      border: none
      width: 100%
      @media screen and (min-width: 768px)
        margin-right: 5px
        margin-bottom: 0
        width: 30%
    .append
      font-size: 13px
      color: #3F4FB7
    .buttonHolder
      width: 100%
      @media screen and (min-width: 768px)
        width: unset
    .button
      width: 100%
      @media screen and (min-width: 768px)
        width: unset
