$chart-width: 500px
$prices-width: 229px

.root
  background-color: #fff
  border-radius: 8px
  display: flex
  padding: 21px 20px 21px 20px
  border: 1px solid #F1F1F6
  margin-bottom: 12px
  flex-wrap: wrap
  @media screen and (min-width: 768px)
    flex-wrap: wrap
    padding: 21px 0 21px 20px
  @media screen and (min-width: 1200px)
    flex-wrap: nowrap
  .images
    // width: 100%
    @media screen and (min-width: 768px)
      width: unset
    .imageItem
      background-color: #B5BCEB
      border-radius: 8px
      width: 60px
      height: 60px
      display: flex
      align-items: center
      justify-content: center
      margin-right: 30px
      margin-bottom: 20px
      background-position: center
      background-size: cover
      background-repeat: no-repeat
      @media screen and (min-width: 768px)
        margin-bottom: 0
  .main
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 20px
    padding-right: 13px
    flex-grow: 1
    width: 100%
    max-width: 100%
    @media screen and (min-width: 1200px)
      // max-width: 500px
      // width: calc(100% - #{$chart-width} - #{$prices-width})
      // max-width: calc(100% - #{$chart-width} - #{$prices-width})
    .summary
      display: flex
    .bottomMain
      display: flex
      gap: 10px
    .title
      font-size: 18px
      font-weight: 700
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 3
      -webkit-box-orient: vertical
    .desc
      display: flex
      justify-content: space-between
      align-items: center
    .amount
      display: flex
      align-items: center
      .text
        margin: 0
        margin-left: 8px
    .text
      color: #92929D
      font-size: 13px
    .variants
      display: flex
      flex-wrap: wrap
      .variantItem
        margin-bottom: 5px
        cursor: pointer
        font-size: 13px
        background-color: #F5E4DE
        padding: 5px 8px
        border-radius: 4px
        margin-right: 10px
  .prices
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    width: 100%
    flex-shrink: 0
    border-left: none
    margin-left: auto
    position: relative
    margin-top: 20px
    @media screen and (min-width: 1200px)
      margin-top: 0
      width: $prices-width
      max-width: 25%
      // border-left: 1px solid #E2E2EA
    .priceMain
      color: #3F4FB7
      font-size: 24px
      font-weight: 700
      position: relative
      margin-bottom: 10px
      width: calc(100% - 30px)
      display: flex
      align-items: center
      justify-content: center
      .dot
        display: block
        width: 8px
        height: 8px
        background-color: #61D644
        border-radius: 50%
        margin-right: 10px
    .text
      color: #11142D
    .price
      font-size: 18px
      font-weight: 700
      width: 100%
      padding: 0 5px
    .linkDown
      font-size: 13px
      color: #3F4FB7
      text-decoration: underline
      cursor: pointer
      user-select: none
      display: flex
      flex-direction: column
      align-items: center
      .downIcon
        transform: rotate(180deg)
  .deleteBtn
    position: absolute
    top: -20px
    right: 10px
    cursor: pointer
    opacity: 0.7
    svg
      width: 15px
      height: 15px
  .heartBtn
    position: absolute
    top: -20px
    right: 40px
    cursor: pointer
    opacity: 0.7
    svg
      width: 15px
      height: 15px
  .link
    overflow: hidden
    text-overflow: ellipsis
  .chart
    width: $chart-width
    display: flex
    justify-content: center
    overflow: auto
    @media screen and (min-width: 768px)
      overflow: visible
      flex: 1
.productSources
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 8px
  overflow: hidden
  background: #fff
  padding: 22px 40px
  margin-bottom: 1rem
  .title
    font-size: 16px
    color: #000
    font-weight: 700
