.form
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 990px)
    flex-direction: column
    grid-row-gap: 12px
  .input
    margin-right: 10px
    background-color: #fff
    border-color: none
    fieldset
      border-style: none
      border-width: 0px
      border: 1px solid #fff !important
      border-radius: 8px
      @media screen and (max-width: 1023px)
        border: 1px solid #D5D5D5
      @media (max-width:523px)
        border: 1px solid #D5D5D5 !important

    input
      border: none
      padding: 14px 22px
    @media (max-width:523px)
      margin: 0
  .bordered_container
    &::before
      content: ''
      position: absolute
      left: 0
      top: 15%
      z-index: 2
      height: 70%
      border-left: 1px solid #DDD
      @media (max-width:523px)
        display: none

  .button
    flex-shrink: 0
    margin-left: 9px
    height: fit-content
  .append
    font-size: 13px
    color: #3F4FB7
    display: flex
    align-items: center
    svg
      margin-right: 11px
