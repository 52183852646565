.loader
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  &.absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute
    height: 100%
    background: rgba(255,255,255,0.5)
    backdrop-filter: blur(2px)
.svg
  path
    stroke: #078C07
