$primary-color: #FB7348

.root
  height: 100vh
  display: flex
  flex-direction: row
  background-image: none
  @media screen and (max-width: 839px)
    background-image: inherit
  .left
    width: 50%
    height: 100%
    display: flex
    background: lightgray 50% / cover no-repeat
    background-position-y: center
    flex-direction: column
    align-items: flex-start
    padding: 41px 64px
    border-radius: 0px 8px 8px 0px
    .leftWrapper
      display: flex
      flex-direction: column
      height: 100%
      @media screen and (max-width: 425px)
        width: 100%
    @media screen and (max-width: 839px)
      border-radius: 0px
      width: 100%
      align-items: center
      .logoContainer
        a
          display: block
    @media screen and (max-width: 425px)
      background: #fff !important
      padding: 16px 15px
      .logoContainer
        a
          svg
            path
              &:nth-child(n+2)
                fill: #000
    .leftText
      font-size: 1.5rem
      font-weight: 500
      color: #FFF
      max-width: 584px
      margin-top: 109px
      @media screen and (max-width: 839px)
        margin-top: 27px
        margin-bottom: 118px
        font-size: 1.125rem
        max-width: 451px
      @media screen and (max-width: 425px)
        display: none  
    .tabletForm
      border-radius: 12px
      background: #fff
      padding: 32px 40px 40px
      display: none
      a
        color: #0844B4
        @media screen and (max-width: 839px)
          display: inline-block
          margin-top: 20px
        @media screen and (max-width: 425px)
          margin-top: auto
      @media screen and (max-width: 839px)
        display: block
        width: 100%
        align-self: center
      @media screen and (max-width: 425px)
        padding: 0
        display: flex
        height: 100%
        margin-top: 79px
        flex-direction: column

    a
      margin-top: auto
      color: #FFF
      text-decoration: underline
      font-size: 0.875rem
      line-height: 1.125rem
      @media screen and (max-width: 839px)
        display: none
  .right
    width: 50%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media screen and (max-width: 839px)
      display: none
