.productName
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    max-width: 90%
.amountInput
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
    input[type=number]
        -moz-appearance: textfield
