.wrapper
  width: 100%
  .subtext
    font-size: 1rem
    color: #666666
    font-weight: 500
    margin-bottom: 32px
    line-height: 1.25rem
  .form
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
  max-width: 24rem
  .input
    margin-bottom: 15px
    & > input
      border: 1px solid rgb(226, 232, 240)
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05)
  .button
    width: fit-content
    flex-shrink: 0
  .error
    text-align: left
    font-weight: 700
    color: red
  @media screen and (max-width: 839px)
    button
      margin-top: 4rem
  @media screen and (max-width: 425px)
    height: 100%
    button
      margin-top: auto
      margin-bottom: 20px
