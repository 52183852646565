.root
  background: #fff
  border: 1px solid #F1F1F6
  border-radius: 8px
  padding: 20px 20px 30px 20px
  min-height: 190px
  position: relative
  display: flex
  flex-direction: column
  .form
    display: flex
    flex-direction: column
    gap: 16px
  .groupBtn
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 8px
    margin-top: 8px