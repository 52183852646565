.newsText
    ul, ol
        padding-left: 2rem
    ul
        list-style: disc
    ol
        list-style: decimal
    img
        cursor: pointer
.newsTable_description
    img
        display: none