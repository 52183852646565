.sourceItem
    color: #3F4FB7
.table
    width: 100%
    border-collapse: separate
    border-spacing: 0 13px
    tr, td, th
        padding: 10px
        background: #fff
        text-align: center
        &:nth-child(1)
            border-top-left-radius: 8px
            border-bottom-left-radius: 8px
            max-width: 250px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
        &:last-child
            border-top-right-radius: 8px
            border-bottom-right-radius: 8px
            background: #fff
        &:nth-child(3)
            max-width: 100px
            white-space: nowrap
            overflow: scroll
            text-overflow: ellipsis
.container
    padding: 0
