$breakpoint-mobile: 767px
$breakpoint-tablet:  768px

.footer
  margin-top: auto
  border: 1px solid #134B98
  border-radius: 89px
  padding: 16px 32px
  background: #134B98
  background-size: cover
  max-width: 1320px
  width: 100%

  @media screen and (max-width: $breakpoint-tablet)
    width: 100%
    display: flex
    padding: 16px 0
  @media screen and (max-width: $breakpoint-mobile)
    border-radius: 12px

  .container
    display: flex
    align-items: center
    justify-content: space-between


.contentFooter
  @media screen and (max-width: $breakpoint-tablet)
    display: flex
    gap: 10px
    flex-direction: row

  @media screen and (max-width: $breakpoint-mobile)
    flex-direction: column