.root
    height: calc(100vh - 4rem)
    .supplier
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08)
        border-radius: 16px
        display: flex
        flex-direction: column
        justify-content: space-between

.tabs
    border-bottom: 1px solid #DDDDDD
    
    .MuiTabs-root
        min-height: 32px

.sticky_header
    th
        background-color: #fff
    td
        padding: 14px

.searchInput
    fieldset
        border: none !important

.textEditor
    p,u, em
        color: #191919
    a
        color: #0A50D4 !important
    p,u,em,a
        font-size: 14px !important
    .ql-snow 
        .ql-tooltip[data-mode=link]::before 
            content: "Enter:" !important 