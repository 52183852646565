@font-face
  font-family: "MulishRegular"
  src: url("./fonts/Mulish/static/Mulish-Black.ttf")

@font-face
  font-family: "InterRegular"
  src: url("./fonts/Inter/Inter-Regular.woff") format('woff')
@font-face
  font-family: "Inter"
  src: url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap)

@font-face 
  font-family: "Roboto"
  src: url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap)
