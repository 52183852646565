.searchBar
    input
        padding: 0px 8px !important
    fieldset
        border-color: #F4F7FE !important
    & div > div
        padding-left: 0px !important
        padding-right: 0px !important
.headerWithServerError
    top: 1.5rem
    @media (min-width:1376px)
        top: 105px
    @media (min-width:1024px) and (max-width:1375px)
        top: 120px
    @media (max-width: 639px)
        top: 10px