.container
  padding: 40px
  background-color: #E2E2EA
  max-height: 70vh
  overflow: scroll
  .row
    padding-top: 6px
    padding-bottom: 6px
    & > div
      overflow: hidden
    & > div:not(:last-child)
      text-overflow: ellipsis
    &:hover
      background: #dbdbdb
    .deleteBtn
      cursor: pointer
  .title
    font-size: 24px
    margin-bottom: 15px
  .input
    margin-bottom: 15px
  .actions
    display: flex
    justify-content: flex-end
    .cancel
      margin-right: 15px
      background-color: transparent !important
      border: 1px solid #FB7348 !important
      color: #FB7348 !important
    .cancel, .ok
      padding: 10px 15px
