.message
    max-width: calc(100% - 3.5rem)
    & a
        text-decoration: underline
        color: #818cf8
        &:hover
            opacity: 0.8

.nameProduct
    background: white
    align-items: center
    display: flex
    gap: 4px
    font-size: 14px
    line-height: 3
    white-space: nowrap
    & a
        &:hover
            text-decoration: underline !important

.messageBox
    a
        color: #0A50D4
        word-break: break-all
        &:hover
            color: #0844B4

.textEditor
    p,u, em
        color: #191919
    a
        color: #0A50D4 !important
    p,u,em,a
        font-size: 14px !important
    &.ql-container.ql-snow
        background-color: black
    > div:first-of-type
        border: none !important
        > div::before
            font-style: normal !important