.container
  padding: 0
  .select
    display: flex
    flex-direction: column
    gap: 13px
    margin-top: 13px
    & > div
      height: 44px
      display: flex
      align-items: center
  .projects
    width: 100%
    overflow: auto
  .projectItem
    border: 1px solid transparent
    transition: border-color 200ms
    margin-bottom: 12px
    border-radius: 8px
    color: #3F4FB7
    cursor: pointer

    &:hover
      border-color: #3F4FB7
    .actions
      display: flex
      .action
        padding: 15px
  .table
    width: 100%
    border-collapse: separate
    border-spacing: 0 13px
    tr
      td, th
        padding: 10px
        background: #fff
        text-align: center
        &:nth-child(1)
          border-top-left-radius: 8px
          border-bottom-left-radius: 8px
          max-width: 150px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        &:last-child
          border-top-right-radius: 8px
          border-bottom-right-radius: 8px
          background: #fff
        &:nth-child(3)
          max-width: 200px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        // &:nth-child(4)
        //   max-width: 50px
        //   white-space: nowrap
        //   overflow: hidden
        //   text-overflow: ellipsis
  .status
    font-weight: 600
    font-size: 10px
    color: #F7D154
    display: flex
    align-items: center
    justify-content: center
    width: max-content
    padding: 5px 12px
    background: #FFF5D2
    border-radius: 8px
    &_del
      background: #FDD4D4
      color: #EE575E
    &_free
      background: #D2FBF0
      color: #2CCEA5
    &_process
      background: #9090ff
      color: #fff
    &_pending
      background: #d9d9d9
      color: #fff
  .right
    position: sticky
    top: 75px
    margin: 13px 0
    display: flex
    flex-direction: column
    gap: 12px
