.container
  padding: 0
  .form
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 10px
    @media screen and (min-width: 768px)
      flex-wrap: nowrap
    .calendarInput
      margin-right: 10px
      flex-grow: 1
    .exportBtn
      white-space: nowrap
      margin-left: 40px
  .selectMonth
    margin-top: 15px
    margin-bottom: 15px
    border: 1px solid #9AA3E1
    padding: 10px 15px 0
    display: flex
    align-items: center
    flex-wrap: wrap
  .title
    color: #9AA3E1
    font-size: 13px
    margin-bottom: 10px
    margin-right: 10px
  .button
    margin: 0 5px 10px
  .box
    margin-bottom: 15px
    padding: 15px
    background: #fff
    height: 100px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 8px
    text-align: center
    .value
      font-size: 24px
      font-weight: 600
      color: #3F4FB7
    .text
      font-size: 14px
  .noStatistics
    text-align: center
  .savedSources
    display: flex
    flex-direction: column
    justify-content: center

