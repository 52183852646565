input[type="time"] {
    &::-webkit-inner-spin-button {
        // Убирает иконку
        display: none;
}

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 0; // Убирает иконку выбора даты
}

    background-color: transparent;
    outline: none;

    &:focus {
        outline: none !important; // Убирает обводку при фокусе
}

    &:valid {
        color: black; // Цвет текста, когда введено корректное значение
    }
}

