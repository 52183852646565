.container
  padding: 40px
  background-color: #E2E2EA
  .title
    font-size: 24px
    margin-bottom: 15px
  .actions
    display: flex
    justify-content: flex-end
    .cancel
      margin-right: 15px
      background-color: transparent !important
      border: 1px solid #FB7348 !important
      color: #FB7348 !important
    .cancel, .ok
      padding: 10px 15px
