/* Изменение фона календаря */
.react-datepicker {
    background-color: #F7F7F7 !important;
    border: 1px solid #EEEEEE !important;
    border-radius: 0.5rem !important;
    box-shadow: 4px 4px 12px 0 #00000026;
    text-transform: capitalize;
    padding: 11px;
}

/* Цвет дней следующего месяца */
.react-datepicker__day--outside-month {
    color: #7F7F7F !important;
}


.react-datepicker__header {
    padding-top: 0 !important;
    background-color: #F7F7F7 !important;
    border-bottom: none !important;
}


/*Стили для обеих стрелок */
.react-datepicker__navigation {
    margin-top: 6px;
}

/* Изменение цвета дней недели */
.react-datepicker__day-name {
    color: #7F7F7F; /* Темно-серый текст */
}

/* Убираем лишние элементы при открытии*/
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    display: none;
}



/* Изменение цвета чисел */
.react-datepicker__day {
    color: #191919; /* Темно-серый текст для чисел */
}

/* Изменение цвета выбранного дня */
.react-datepicker__day--selected {
    background-color: #134B98;
    color: #FCFCFC;
    border-radius: 8px !important;
    font-weight: 500;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}

.react-datepicker__day--today {
    background-color: rgba(173, 178, 189, 0.39) !important;
    color: #fff;
    border-radius: 8px !important;
}

/* Ховер эффект для дней */
.react-datepicker__day:hover {
    background: rgba(19, 75, 152, 0.24);
    color: #ffffff;
    border-radius: 8px !important;
}

/* Изменение цвета стрелок навигации */
.react-datepicker__navigation-icon::before {
    border-color: #191919;
}

/* Кнопка выбора месяца/года */
.react-datepicker__current-month {
    color: #191919;
    font-weight: 500;
    margin-bottom: 17px;
}
